<ih-basic-dialog [scrollable]="true">
  <ng-container ih-header>Profile privacy</ng-container>
  <ng-container ih-content>
    <div [formGroup]="privacyForm" class="fx-layout-column fx-flex-auto">
      <div class="explanation-header">Choose which information to display on your public profile</div>
      <div class="privacy-option">
        <mat-slide-toggle
          labelPosition="before"
          aria-label="Show Job title on profile"
          formControlName="privateJobTitle"
        >
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M22,4H14V7H10V4H2A2,2 0 0,0 0,6V20A2,2 0 0,0 2,22H22A2,2 0 0,0 24,20V6A2,2 0 0,0 22,4M8,9A2,2 0 0,1 10,11A2,2 0 0,1 8,13A2,2 0 0,1 6,11A2,2 0 0,1 8,9M12,17H4V16C4,14.67 6.67,14 8,14C9.33,14 12,14.67 12,16V17M20,18H14V16H20V18M20,14H14V12H20V14M20,10H14V8H20V10M13,6H11V2H13V6Z"
              />
            </svg>
          </mat-icon>
          <span>Job title</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option">
        <mat-slide-toggle labelPosition="before" aria-label="Show about on profile" formControlName="privateAbout">
          <mat-icon>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z"
              />
            </svg>
          </mat-icon>
          <span>About</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option">
        <mat-slide-toggle labelPosition="before" aria-label="Show email on profile" formControlName="privateEmail">
          <mat-icon svgIcon="email"></mat-icon>
          <span>Email</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option">
        <mat-slide-toggle
          labelPosition="before"
          aria-label="Show phone number on profile"
          formControlName="privatePhone"
        >
          <mat-icon svgIcon="cellphone"></mat-icon>
          <span>Phone</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option">
        <mat-slide-toggle
          labelPosition="before"
          aria-label="Show websites on profile"
          formControlName="privateWebsites"
        >
          <mat-icon svgIcon="link"></mat-icon>
          <span>Websites</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option privacy-option-profile-section" *ngFor="let group of privacy.tagGroups; index as i">
        <mat-slide-toggle
          labelPosition="before"
          aria-label="{{ group.name }}"
          [formControl]="getTagGroupHiddenControl(i)"
        >
          <mat-icon [innerHtml]="group.svg! | safe: 'html'"></mat-icon>
          <span>{{ group.name }}</span>
        </mat-slide-toggle>
      </div>

      <div class="privacy-option">
        <mat-slide-toggle
          labelPosition="before"
          aria-label="Show channels on profile"
          formControlName="privateChannels"
        >
          <mat-icon svgIcon="pound"></mat-icon>
          <span>Channels</span>
        </mat-slide-toggle>
      </div>
    </div>
  </ng-container>
  <ng-container ih-footer>
    <button mat-button (click)="cancel()">CANCEL</button>
    <button mat-button color="primary" class="dark-font" (click)="submit()">SAVE</button>
  </ng-container>
</ih-basic-dialog>
